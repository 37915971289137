<template>
  <div
    class="icon-wrapper"
    :class="props.class"
    :style="{
      height: props.size,
    }"
  >
    <div :style="style" class="lds-spinner">
      <div :class="backgroundColor" v-for="index in 12" :key="index"></div>
    </div>
  </div>
</template>

<script lang="ts" setup>
interface ILoadingIconProps {
  size?: string;
  class?: string;
  backgroundColor?: string; // made for tailwindcss classes
}

const props = withDefaults(defineProps<ILoadingIconProps>(),{
  size: '80px',
  backgroundColor: 'bg-white'
});

const style = computed(() => {
  return {
    "--size": props.size,
  }
})
</script>

<style scoped>
/* Source  https://loading.io/css/ */
.lds-spinner {
  --size: 80px;

  color: official;
  display: inline-block;
  position: relative;
  width: var(--size);
  height: var(--size);
}
.lds-spinner div {
  transform-origin: calc(var(--size) / 2) calc(var(--size) / 2);
  animation: lds-spinner 1.2s linear infinite;
}

.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: calc(var(--size) / (80 / 3));
  left: calc(var(--size) / (80 / 37));
  width: calc(var(--size) / (80 / 6));
  height: calc(var(--size) / (80 / 18));
  border-radius: 20%;
  background-color: inherit;
}
.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>
